import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

export default function PlanList({ status, type }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const childId = useParams().id;
  const [plans, setPlans] = useState([]);
  const controller = type ? 'sub-plan' : 'plan';

  useEffect(() => {
    status ??= '';
    fetch(`/api/child/${childId}/${controller}/list?status=${status}`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log(data);
          // TODO Update on status change
          const order = ['ACTIVE', 'INACTIVE', 'COMPLETED'];
          data.plans.sort(
            (a, b) => order.indexOf(a.status) - order.indexOf(b.status)
          );
          setPlans(data.plans);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId, status, type]);

  function deletePlan(planId) {
    fetch(`/api/child/${childId}/${controller}/${planId}/delete`, {
      method: 'POST'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setPlans(plans.filter((plan) => plan.id !== planId));
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function updateStatus(planId, status) {
    fetch(`/api/${controller}/${planId}/set-status?status=${status}`, {
      method: 'POST'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setPlans(
            plans.map((plan) => {
              if (plan.id === planId) {
                plan.status = status;
              }
              return plan;
            })
          );
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <main>
        <div className="py-5 text-center">
          <h3>{type ? t('Sub-plans') : t('Plans')}</h3>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t('Name')}</th>
              <th scope="col">{t('Description')}</th>
              <th scope="col">{t('Status')}</th>
              <th scope="col">{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <tr key={plan.id}>
                <td>
                  <Link to={`/child/${childId}/${controller}/${plan.id}`}>
                    {plan.name}
                  </Link>
                  <i
                    className="las la-pencil-alt"
                    style={{ marginLeft: '10px' }}
                    onClick={() =>
                      navigate(
                        `/child/${childId}/${controller}/${plan.id}/edit`
                      )
                    }
                  ></i>
                </td>
                <td>
                  {plan.description.length > 20
                    ? plan.description.substr(0, 20) + '...'
                    : plan.description}
                </td>
                <td>
                  <select
                    className="form-select"
                    value={plan.status}
                    onChange={(e) => updateStatus(plan.id, e.target.value)}
                  >
                    <option value="INACTIVE">{t('Inactive')}</option>
                    <option value="ACTIVE">{t('Active')}</option>
                    <option value="COMPLETED">{t('Completed')}</option>
                  </select>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deletePlan(plan.id)}
                  >
                    {t('Delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/child/${childId}/${controller}/create`)}
        >
          {type ? t('Create sub-plan') : t('Create plan')}
        </button>
      </main>
    </div>
  );
}
