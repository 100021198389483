import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { UserContext } from '../UserContext';

export default function Login() {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  let location = useLocation();

  // TODO validate if email and password are not empty
  function submitForm(event) {
    event.preventDefault();
    fetch('/api/login', { method: 'POST', body: JSON.stringify(user) })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          userContext.login(data.email, data.role);
          if (location.state) {
            navigate(location.state);
            return;
          }
          if (data.child_id) {
            navigate(`/child/${data.child_id}/plan/list`);
          } else {
            navigate('/child/create');
          }
        } else {
          location.state = null;
          setError(t(data.error));
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="container">
      <div className="py-5 text-center">
        <h2>{t('Login')}</h2>
      </div>
      {location?.state?.message && (
        <div className="alert alert-success">{location.state.message}</div>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form
        className="needs-validation form-group text-left"
        onSubmit={submitForm}
      >
        <div className="row g-3" style={{ textAlign: 'left' }}>
          <div className="col-12">
            <label className="form-label">{t('Email address')}</label>
            <input
              type="email"
              className="form-control"
              required
              data-testid="email"
              id="email"
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Password')}</label>
            <input
              type="password"
              className="form-control"
              required
              data-testid="password"
              id="password"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
          </div>
          <div className="col-12">
            <button
              className="w-100 btn btn-primary btn-lg"
              type="submit"
              data-testid="login-btn"
            >
              {t('Login')}
            </button>
          </div>
          <div className="text-center">
            <Link to="/reset-password">{t('Forgot password?')}</Link>
          </div>
        </div>
      </form>
    </div>
  );
}
