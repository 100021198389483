import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import MessageToast from '../../../components/MessageToast';
import ABC from '../../../components/behavior/ABC';

export default function BehaviorList() {
  const { t } = useTranslation();
  const childId = useParams().id;
  const [behaviors, setBehaviors] = useState([]);
  const [selectedBehavior, setSelectedBehavior] = useState(null);
  const [currentBehavior, setCurrentBehavior] = useState('');
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    fetch(`/api/child/${childId}/behavior/list`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setBehaviors(data.behaviors);
          if (data.behaviors.length > 0) {
            setSelectedBehavior(data.behaviors[0].id);
          }
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId]);

  function createBehavior() {
    fetch(`/api/child/${childId}/behavior/create`, {
      method: 'POST',
      body: JSON.stringify({ name: currentBehavior })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log(data);
          setBehaviors([...behaviors, data.behavior]);
          setCurrentBehavior('');
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function deleteBehavior(behaviorId) {
    fetch(`/api/child/${childId}/behavior/${behaviorId}/delete`, {
      method: 'POST'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setBehaviors(
            behaviors.filter((behavior) => behavior.id !== behaviorId)
          );
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function logBehavior(behaviorId) {
    fetch(`/api/behavior/${behaviorId}/log`, { method: 'POST' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log(data);
          setToasts([...toasts, { message: 'Behavior logged' }]);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <h1>{t('Behaviors')}</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">{t('Name')}</th>
          </tr>
        </thead>
        <tbody>
          {behaviors.map((behavior, index) => (
            <tr key={index}>
              <td>
                <Link to={`/child/${childId}/behavior/${behavior.id}`}>
                  {behavior.name}
                </Link>
              </td>
              <td>
                <button
                  onClick={() => logBehavior(behavior.id)}
                  className="btn btn-primary"
                >
                  {t('Log behavior')}
                </button>
              </td>
              <td>
                <button
                  onClick={() => deleteBehavior(behavior.id)}
                  className="btn btn-danger"
                >
                  {t('Delete')}
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2}>
              <input
                type="text"
                className="form-control"
                value={currentBehavior}
                placeholder={t('Behavior name')}
                onChange={(e) => setCurrentBehavior(e.target.value)}
              />
            </td>
            <td>
              <button className="btn btn-primary" onClick={createBehavior}>
                {t('Create behavior')}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {toasts.map((toast, index) => (
        <MessageToast key={index} message={t(toast.message)} />
      ))}
      <br />
      <h2>{t('Behavior ABC')}</h2>
      <div className="row">
        <select
          className="form-select col-4"
          onChange={(event) => setSelectedBehavior(event.target.value)}
        >
          {behaviors.map((behavior, index) => (
            <option key={index} value={behavior.id}>
              {behavior.name}
            </option>
          ))}
        </select>
        {selectedBehavior && <ABC behaviorId={selectedBehavior} />}
      </div>
    </div>
  );
}
