import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function EvaluationResult({ evaluation, onDeleteEvaluation }) {
  const { t } = useTranslation();

  function deleteEvaluation(id) {
    fetch(`/api/item-evaluation/${id}/delete`, { method: 'POST' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          onDeleteEvaluation(id);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function convertUTCDateToLocalDate(dateString) {
    const date = new Date(dateString);
    const newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  const deletePopover = (
    <Popover id={`popover-${evaluation.id}`} title={t('Delete evaluation?')}>
      <Popover.Header as="h3">{t('Delete evaluation?')}</Popover.Header>
      <Popover.Body className="text-center">
        {evaluation.user_id && (
          <>
            <p>
              {t('Author')}:{' '}
              {evaluation.user_first_name + ' ' + evaluation.user_last_name}
            </p>
            <p>
              {t('Date')}:{' '}
              {convertUTCDateToLocalDate(evaluation.date).toLocaleString()}{' '}
            </p>
          </>
        )}

        <br />
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => deleteEvaluation(evaluation.id)}
        >
          <i className="las la-trash"></i>
        </button>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={deletePopover}
      rootClose
    >
      <i
        className={`las la-${
          evaluation.result === 'YES' ? 'check' : 'times'
        }-circle icon-large text-${
          evaluation.result === 'YES' ? 'success' : 'danger'
        }`}
      ></i>
    </OverlayTrigger>
  );
}
