import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

export default function ActivateUser() {
  const { t } = useTranslation();
  const token = useParams().token;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/token/activate-user`, {
      method: 'POST',
      body: JSON.stringify({ token: token })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          navigate('/login', {
            state: { message: t('Your account was successfully activated!') }
          });
        } else {
          console.log(data);
          navigate('/login', { state: { message: data.error } });
        }
      })
      .catch((err) => alert(err));
  }, [token]);

  return null;
}
