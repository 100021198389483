import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CreateChild() {
  const { t } = useTranslation();
  const [child, setChild] = useState({});
  const navigate = useNavigate();

  function submitForm(event) {
    event.preventDefault();
    fetch('/api/child/create', { method: 'POST', body: JSON.stringify(child) })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log(data);
          alert(t('Child added successfully'));
          navigate(`/child/${data.child_id}/plan/list`);
        } else {
          console.log(data.error);
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <div className="py-5 text-center">
        <h2>{t('Add child')}</h2>
      </div>
      <form
        className="needs-validation form-group text-left"
        onSubmit={submitForm}
      >
        <div className="row g-3" style={{ 'text-align': 'left' }}>
          <div className="col-12">
            <label className="form-label">{t('First name')}</label>
            <input
              type="text"
              className="form-control"
              required
              onChange={(e) =>
                setChild({ ...child, first_name: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Last name')}</label>
            <input
              type="text"
              className="form-control"
              required
              onChange={(e) =>
                setChild({ ...child, last_name: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Date of birth')}</label>
            <input
              type="date"
              className="form-control"
              required
              onChange={(e) =>
                setChild({ ...child, date_of_birth: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <button className="w-100 btn btn-primary btn-lg" type="submit">
              {t('Add child')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
