import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function ABCList({ behaviorId }) {
  const { t } = useTranslation();
  const childId = useParams().id;
  const [abcList, setAbcList] = useState([]);

  useEffect(() => {
    fetch(`/api/child/${childId}/behavior-abc/${behaviorId}/list`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error && data.abc) {
          let list = data.abc.map((element) => {
            element.created_at = new Date(element.created_at);
            element.created_at.setMilliseconds(0);
            return element;
          });
          setAbcList(list);
        } else {
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [behaviorId]);

  return (
    <div>
      <h2>{t('Log')}</h2>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" data-testid="date-header">
              {t('Date')}
            </th>
            <th scope="col" data-testid="antecedent-header">
              {t('Antecedent')}
            </th>
            <th scope="col" data-testid="consequence-header">
              {t('Consequence')}
            </th>
          </tr>
        </thead>
        <tbody>
          {abcList.map((abc, index) => (
            <tr key={index}>
              <td data-testid={`abc-item-${index}-date`}>
                {abc.created_at.toLocaleString('en-gb')}
              </td>
              <td data-testid={`abc-item-${index}-antecedent`}>
                {abc.antecedent}
              </td>
              <td data-testid={`abc-item-${index}-consequence`}>
                {abc.consequence}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
