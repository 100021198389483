import { useState } from 'react';
import Toast from 'react-bootstrap/Toast';

export default function MessageToast({ message }) {
  const [show, setShow] = useState(true);

  return (
    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
      <Toast.Header></Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
}
