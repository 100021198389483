import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function ViewBehavior() {
  const { t } = useTranslation();
  const frequencyTranslated = t('frequency');

  const childId = useParams().id;
  const behaviorId = useParams().behaviorId;

  const [behavior, setBehavior] = useState({});
  const [behaviorFrequencies, setBehaviorFrequencies] = useState([]);

  useEffect(() => {
    fetch(`/api/behavior/${behaviorId}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setBehavior(data.behavior);
          let frequencies = [];
          data.behavior_tracking.sort((a, b) => {
            return a.date_logged > b.date_logged ? 1 : -1;
          });
          data.behavior_tracking.forEach((element) => {
            const date = element.date_logged.split(' ')[0];

            if (
              frequencies.findIndex((element) => element.date === date) === -1
            ) {
              const currentHash = { date: date };
              currentHash[frequencyTranslated] = 0;
              frequencies.push(currentHash);
            }
            frequencies[frequencies.length - 1][frequencyTranslated] += 1;
          });

          setBehaviorFrequencies(frequencies);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId, behaviorId]);

  return (
    <div>
      <h1>{behavior.name}</h1>
      <h3>{behavior.description}</h3>
      <Bar
        data={{
          labels: behaviorFrequencies.map((element) => element.date),
          datasets: [
            {
              label: frequencyTranslated,
              data: behaviorFrequencies.map(
                (element) => element[frequencyTranslated]
              ),
              backgroundColor: '#4bc0c033',
              borderColor: '#4bc0c0',
              borderWidth: 1
            }
          ]
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }}
      />
    </div>
  );
}
