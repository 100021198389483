import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// A list that collapses when an item is clicked
export default function RadioList({ type, onSelect }) {
  const { t } = useTranslation();

  const selectedItemRef = useRef(null);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [otherItem, setOtherItem] = useState(null);

  useEffect(() => {
    fetch(`/api/list/${type}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setList(data.list);
        } else {
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [type]);

  function onItemClicked(index, item) {
    if (selected === index) {
      setSelected(null);
      onSelect(null);
      return;
    }
    setSelected(index);
    onSelect(item);
  }

  function onOtherItemChanged(e) {
    setOtherItem(e.target.value);
    onSelect(e.target.value);
  }

  function scroolToElement() {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  return (
    <div className="list-group">
      {list.map(
        (item, index) =>
          (selected === null || selected === index) && (
            <div
              key={index}
              className={
                'list-group-item list-group-item-action fw-bold rounded-2 ' +
                'mb-1 border-1' +
                (selected === index ? ' active' : '')
              }
              onClick={() => {
                onItemClicked(index, item);
                scroolToElement();
              }}
              data-testid={`${type}-item-${index}`}
            >
              <input
                className="form-check-input me-1"
                type="radio"
                readOnly
                ref={selectedItemRef}
                id={type + '-' + index}
                checked={selected === index}
              />
              <label className="form-check-label" htmlFor={type + '-' + index}>
                {item}
              </label>
            </div>
          )
      )}
      {(selected === null || selected === 'other') && (
        <div
          className={
            'list-group-item list-group-item-action rounded-2 border-1' +
            (selected === 'other' ? ' active' : '')
          }
          style={{ padding: '0.2rem' }}
          onClick={() => {
            onItemClicked('other', otherItem);
            scroolToElement();
          }}
          data-testid={`${type}-other`}
        >
          <input
            className="form-check-input me-1 mt-2"
            style={{ marginInlineStart: '0.8rem' }}
            type="radio"
            ref={selectedItemRef}
            id={type + '-other'}
            checked={selected === 'other'}
            readOnly
          />
          <label
            className="form-check-label"
            htmlFor={type + '-other'}
            style={{ width: '89%' }}
          >
            <input
              type="text"
              className="form-control p-1"
              placeholder={t('Other')}
              onChange={onOtherItemChanged}
            />
          </label>
        </div>
      )}
    </div>
  );
}
