import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function AdminMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="dropup">
      <a
        className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        href="#"
      >
        <img
          src="https://img.icons8.com/ios/50/settings-3--v1.png"
          alt=""
          width="32"
          height="32"
          className="me-2"
        />
        <strong>Admin</strong>
      </a>
      <ul className="dropdown-menu text-small shadow">
        <li>
          <button
            className="dropdown-item"
            onClick={() => navigate('/admin/subscriptions')}
          >
            {t('Subscriptions')}
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            onClick={() => navigate('/admin/users')}
          >
            {t('Users')}
          </button>
        </li>
      </ul>
      <br />
    </div>
  );
}
