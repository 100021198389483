import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function Billing() {
  const { t } = useTranslation();
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [step, setStep] = useState(1);
  const location = useLocation();

  useEffect(() => {
    fetch('/api/user/subscription-data')
      .then((response) => response.json())
      .then((data) => {
        setTotalSubscriptions(data.total);
        setSubscriptions(data.subscriptions_in_use);

        fetch('/api/child/list')
          .then((response) => response.json())
          .then((data2) => {
            const availableChildren = data2.children.filter(
              (child) =>
                data.subscriptions_in_use.findIndex(
                  (subscription) => subscription.child_id === child.id
                ) === -1
            );
            setChildren(availableChildren);
            setSelectedChild(availableChildren[0].id);
          });
      });
  }, [step]);

  function addChildLicense() {
    fetch('/api/child/add-license', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ child_id: selectedChild })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!data.error) {
          setStep(step + 1);
        } else {
          console.log(data.error);
        }
      })
      .catch((error) => console.log(error));
  }

  function removeChildLicense(id) {
    fetch('/api/child/remove-license', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ child_subscription_id: id })
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          setStep(step + 1);
        }
      });
  }

  return (
    <div>
      <div className="py-5">
        <h1>{t('Billing')}</h1>
      </div>
      {location.state && location.state.message && (
        <div className="alert alert-danger" role="alert">
          {t(location.state.message)}
        </div>
      )}
      <div className="card" style={{ width: '20rem' }}>
        <div className="card-header">
          <h5 className="card-title">{t('Your subscription')}</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <strong className="text-left">{t('Active licenses')}:</strong>
            </div>
            <div className="col-md-6">
              <p>{totalSubscriptions}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <strong className="text-left">{t('Licenses in use')}:</strong>
            </div>
            <div className="col-md-6">
              <p>{subscriptions.length}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <a
                href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                {t('Manage')}
              </a>
            </div>
            <div className="col">
              <a
                href={process.env.REACT_APP_STRIPE_PRODUCT_LINK}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                {t('Add licenses')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">{t('Your children')}</h5>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('Name')}</th>
                <th scope="col">{t('Status')}</th>
                <th scope="col">{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription, index) => (
                <tr key={index}>
                  <td>
                    {subscription.child_first_name +
                      ' ' +
                      subscription.child_last_name}
                  </td>
                  <td>{t(subscription.status)}</td>
                  <td>
                    <i
                      className="las la-trash"
                      onClick={() =>
                        removeChildLicense(subscription.child_subscription_id)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {children.length > 0 && (
          <div className="card-footer">
            <div className="input-group">
              <select
                className="form-select"
                onChange={(event) => setSelectedChild(event.target.value)}
                value={selectedChild}
              >
                <option>{t('Select a child')}</option>
                {children.map((child) => (
                  <option key={child.id} value={child.id}>
                    {child.first_name + ' ' + child.last_name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addChildLicense}
              >
                {t('Add child')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
