import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function Dashboard() {
  const { t } = useTranslation();
  const [yesTranslated, noTranslated] = [t('YES'), t('NO')];
  const childId = useParams().id;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch(`/api/child/${childId}/evaluations`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          let bars = [];
          data.evaluations.sort((a, b) => {
            return a.date > b.date ? 1 : -1;
          });
          data.evaluations.forEach((element) => {
            const date = element.date.split(' ')[0];
            if (bars.findIndex((element) => element.date === date) === -1) {
              const currentHash = { date: date };
              currentHash[yesTranslated] = 0;
              currentHash[noTranslated] = 0;
              bars.push(currentHash);
            }
            const resultTranslated = t(element.result);
            bars[bars.length - 1][resultTranslated] += 1;
          });
          console.log(bars);
          setChartData(bars);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId]);

  return (
    <div>
      <Bar
        data={{
          labels: chartData.map((element) => element.date),
          datasets: [
            {
              label: yesTranslated,
              data: chartData.map((element) => element[yesTranslated]),
              backgroundColor: '#4bc0c033',
              borderColor: '#4bc0c0',
              borderWidth: 1
            },
            {
              label: noTranslated,
              data: chartData.map((element) => element[noTranslated]),
              backgroundColor: '#ff638433',
              borderColor: '#ff6384',
              borderWidth: 1
            }
          ]
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }}
      />
    </div>
  );
}
