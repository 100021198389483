import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { UserContext } from '../../UserContext';
import AdminMenu from './AdminMenu';
import ChildLinks from './ChildLinks';
import UserMenu from './UserMenu';

export default function SideBar() {
  const { t } = useTranslation();
  const childId = useParams().id;
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-xxl navbar-light bg-light">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebar"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="sidebar">
        <div
          className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary"
          style={{ height: '100vh' }}
        >
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link
                to="/"
                className="align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none nav-link"
              >
                <img src="/logo-01.png" alt="" width="110" />
                <br />
                AbaWell Track
              </Link>
            </li>
          </ul>
          <hr />
          {childId && <ChildLinks />}
          <br />
          <br />

          {userContext.currentUser &&
            userContext.currentUser.role === 'ADMIN' && <AdminMenu />}
          {userContext.currentUser && <UserMenu />}
          {!userContext.currentUser && (
            <a
              className="link w-100"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target={window.innerWidth < 768 && '#sidebar'}
              onClick={() => navigate('/login')}
            >
              {t('Login')}
            </a>
          )}
          {!userContext.currentUser && (
            <a
              className="link w-100"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target={window.innerWidth < 768 && '#sidebar'}
              onClick={() => navigate('/register')}
            >
              {t('Register')}
            </a>
          )}
        </div>
      </div>
    </nav>
  );
}
