export default function Terms() {
  const htmlString = `
  <p>
    <strong>TERMENI ȘI CONDIȚII</strong>
  </p>
  <p>
    <strong>&nbsp;</strong>
  </p>
  <p>
    &nbsp;
  </p>
  <p>
    <strong>SECȚIUNEA I. PARTEA INTRODUCTIVĂ</strong> 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>1.</strong> Vă mulțumim pentru interesul manifestat față de aplicația track.abawell.ro (denumită în continuare „aplicația/e”) și față serviciile pe care le oferim.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>2. </strong>Pentru a putea utiliza aplicația este necesar să citiți și să acceptați prevederile acestor termeni și condiții. În cazul neacceptării vă rugăm să nu accesați și să nu utilizați aplicația, software-ul, funcționalitățile, informațiile și materialele cuprinse în aplicație.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>3. </strong>Prevederile termenilor și condițiilor prezente reprezintă un acord ce se încheie între dumneavoastră denumit/ă în continuare "Beneficiar" și societatea Abawell S.R.L., cu sediul în Bulevardul Nicolae Titulescu, Nr. 27, Bloc D1, Et. 4, Ap. 9, Cluj-Napoca, Jud. Cluj, România, C.U.I. 48959012, înmatriculată la Registrul Comerțului sub nr. J12/4397/17.10.2023, e-mail petru@wellcode.com, reprezentată legal prin dl. Trîmbițaș Tiberiu Petru, în calitate de administrator, denumită în continuare "Furnizorul".
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>4. </strong>Termenii utilizați sunt definiți conform celor ce urmează:
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>BENEFICIAR –</strong> persoana ce utilizează aplicația;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>FURNIZORUL</strong> – persoana juridică (<em>Bulevardul Nicolae Titulescu, Nr. 27, Bloc D1, Et. 4, Ap. 9, Cluj-Napoca, Jud. Cluj, România, C.U.I. 48959012, înmatriculată la Registrul Comerțului sub nr. J12/4397/17.10.2023, e-mail petru@wellcode.com</em>) care deține și pune la dispoziția Beneficiarului aplicația;
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>REPREZENTANT</strong> <strong>(în cazul persoanelor juridice)</strong> - persoana/persoanele care în virtutea funcției pe care o dețin în cadrul persoanei juridice (ex. administrator, director) sau în baza unui contract de mandat încheiat cu persoana juridică poate să o reprezinte în raporturile cu Prestatorul;
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>APLICAȚIA – </strong>aplicația online (sau aplicație web) ce este accesibilă printr-un browser de internet (Google Chrome, Internet Explorer, Mozilla Firefox, etc.) având adresa web<strong> </strong>track.abawell.ro și care poate fi utilizată de către Beneficiar conform celor descrise în secțiunea III;
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>SITE-UL WEB AL APLICAȚIEI – </strong>gruparea de pagini web multimedia ce conține texte, imagini, animații, sunete, etc. referitoare la aplicație și furnizor; &nbsp; 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>CONT DE UTILIZATOR – </strong>mijlocul prin care Beneficiarului se autentifică pe site-ul web al aplicației, utilizând sisteme ce pot opera date și informații (ex. calculator, smartphone, etc), dobândind astfel posibilitatea de a utiliza aplicația;&nbsp; <strong>&nbsp;</strong>
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong>CAZ FORTUIT</strong> - <a id="id_crtA5663"></a><a id="id_crtA5663_bdy"></a><a id="id_ttlA5675"></a><a id="id_ttlA5675_bdy"></a><a id="id_capA6453"></a><a id="id_capA6453_bdy"></a><a id="id_secA6468"></a><a id="id_secA6468_bdy"></a><a id="id_artA6470"></a><a id="id_artA6470_bdy"></a><a id="id_litA6474"></a><a id="Bookmark"></a><span style="color:#000000">un eveniment care nu poate fi prevăzut și nici împiedicat de către cel care ar fi fost chemat să răspundă dacă evenimentul nu s-ar fi produs;</span>
  </p>
  <p style="text-align:justify">
    <span style="color:#000000">&nbsp;</span>
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span><strong><span style="color:#000000">FORȚA MAJORĂ</span></strong><span style="color:#000000"> - </span><a id="id_crtA56631"></a><a id="id_crtA5663_bdy1"></a><a id="id_ttlA56751"></a><a id="id_ttlA5675_bdy1"></a><a id="id_capA64531"></a><a id="id_capA6453_bdy1"></a><a id="id_secA64681"></a><a id="id_secA6468_bdy1"></a><a id="id_artA64701"></a><a id="id_artA6470_bdy1"></a><a id="id_litA6473"></a><a id="Bookmark1"></a><span style="color:#000000">orice eveniment extern, imprevizibil, absolut invincibil și inevitabil;</span> 
  </p>
  <p style="text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p style="text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p style="text-align:justify">
    <strong>SECȚIUNEA II. CREAREA CONTULUI</strong>
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>1.</strong> Pentru a putea accesa și utiliza aplicația Beneficiarul trebuie să își creeze un cont de utilizator făcând click pe butonul denumit „înregistrare” de pe site-ul web al aplicației ce poate fi accesat utilizând următoarea adresă web: track.abawell.ro. Beneficiarul va trebui să urmeze toți pașii ce sunt afișați după ce a făcut click pe butonul de înregistrare și să introducă informațiile solicitate (pentru mai multe detalii legate de informațiile solicitate în cadrul procesului de înregistrare vă rugăm să citiți secțiunea VI).
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>
  </p>
  <p style="text-align:justify">
    <strong>2. </strong>Persoanele juridice vor putea crea conturi prin reprezentantul acestora. Dacă Beneficiarul este unul dintre angajații sau colaboratorii persoanei juridice atunci persoana juridică va răspunde pentru modul în care prevederile prezentului acord sunt respectate de către cei din urmă.&nbsp; 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>3.</strong> Atunci când își creează contul Beneficiarul va selecta un nume de utilizator și o parolă de acces la cont. Beneficiarul se obligă să nu împartă contul sau informațiile de acces la cont cu alte persoane, excepția fiind cazurile în care contul Beneficiarului este creat de către reprezentant.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>4.</strong> Crearea contului va permite Beneficiarului să acceseze și să utilizeze aplicația. Astfel, Beneficiarul va putea accesa oricând aplicația, în scopul realizării activităților de terapie pentru persoanele cu autism, cu excepția perioadelor de mentenanță tehnică și a eventualelor situații de forță majoră sau caz fortuit, precum și alte situații ce nu depind de voința Furnizorului. 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>5.</strong> Accesul la aplicație va putea fi suspendat, limitat sau revocat ca urmare a nerespectării codului de conduită de la secțiunea IV sau a celorlalte obligații ce rezultă din acordurile încheiate cu Furnizorul. De asemenea, accesul va putea fi suspendat sau limitat pentru realizarea lucrărilor de mentenanță, reparație sau întreținere necesare.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>SECȚIUNEA III. DESCRIEREA APLICAȚIEI</strong>
  </p>
  <p style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>&nbsp;&nbsp; 
  </p>
  <p style="text-align:justify">
    <strong>1. </strong>Prezenta aplicație este destinată psihoterapeuților și coordonatorilor care lucrează cu persoane ce suferă de autism.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>2. </strong>Cu ajutorul aplicației Beneficiarul va putea crea planuri de intervenție personalizate pentru persoanele cu autism și va putea măsura comportamentele acestora. 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>3. </strong>Datele colectate de către Beneficiar vor fi stocate și centralizate cu ajutorul aplicației, ușurând astfel procesul de monitorizare a evoluției fiecărei persoane cu care lucrează.
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p style="text-align:justify">
    <strong>4.</strong> Furnizorul va putea adăuga periodic funcții noi și nu are obligația să introducă anumite funcționalități la solicitarea Beneficiarului. 
  </p>
  <p style="text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>SECȚIUNEA IV.</strong> <strong>CODUL DE CONDUITĂ</strong>
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>1.</strong> Utilizarea aplicației este guvernată de prezentul cod de conduită.
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>2.</strong> Beneficiarul se obligă să:
  </p>
  <ol class="awlist1" style="margin:0pt; padding-left:0pt">
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.83pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>nu copieze, traducă, reproducă, modifice, descarce, transfere, transmită către alte persoane, să nu creeze opere derivate sau să nu realizeze orice alte operațiuni neautorizate de către Furnizor asupra codului sursă al aplicației, aplicației, al conținutului acesteia și al conținutului ce aparține site-ului aplicației;&nbsp; 
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.15pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>nu aibă un comportament neadecvat (limbaj impropriu, manifestări indecente, insulte, comportament contrar bunelor moravuri, etc.);
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.83pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>respecte obligația de confidențialitate ce îi revine în legătură cu datele cu caracter personal de care a luat cunoștință pe parcursul utilizării aplicației și a interacțiunilor cu Furnizorul;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.15pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>utilizeze aplicația conform destinației acesteia;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.83pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>nu realizeze activități (de orice fel) ce pot afecta: buna funcționare a aplicației și a serverelor ce stau la baza funcționării acesteia sau accesul la aplicație de către alți Beneficiari;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:6.16pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp;&nbsp; </span>nu încerce să obțină acces neautorizat la serverele aplicației, la alte conturi, sisteme de calculatoare, rețele conectate la orice server al aplicației;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.15pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>nu împartă datele contului, conținutul aplicației și datele pe care le obține și le stochează pe aplicație cu alte persoane;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:4.15pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp; </span>nu răspândească informații false despre Furnizor și aplicație;
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:6.82pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp;&nbsp;&nbsp; </span>informeze reprezentanții (<em>părinți, tutori, etc.</em>) persoanelor cu autism și (<em>acolo unde este posibil</em>) însăși persoanele cu autism care sunt datele cu caracter personal ce urmează să fie prelucrate de către aplicație, scopurile prelucrării și să obțină acordul acestora în vederea prelucrării (<em>pentru a vedea care sunt datele ce se prelucrează de către aplicație vă rugăm să citiți secțiunea VI referitoare la politica noastră de confidențialitate</em>);
    </li>
    <li class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-indent:-14.15pt; text-align:justify">
      <span style="width:6.82pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp;&nbsp;&nbsp; </span>să nu aibă orice alt tip de comportament ce contravine bunei-credințe și legilor în vigoare în raporturile pe care le are cu Furnizorul și cu persoanele în legătură cu care este utilizată aplicația; 
    </li>
  </ol>
  <p class="BodyText" style="margin-left:35.35pt; margin-bottom:0pt; text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>SECȚIUNEA V. DECLINAREA RESPONSABILITĂȚII</strong>
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>1. </strong>Furnizorul nu răspunde pentru prejudiciile pe care Beneficiarul sau, după caz, reprezentantul le suferă ca urmare a uneia sau a mai multora dintre următoarele fapte:
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>a) lipsa de performanță sau performanța redusă a mijloacelor tehnice utilizate de către Beneficiar (calculator, webcam, microfon, etc.);
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>b) imposibilitatea de a accesa sau îngreunarea accesului la aplicație din cauza unor defecțiuni/probleme ale mijloacelor sale tehnice sau a unor defecțiuni/probleme legate de conexiunea acestuia la internet;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>c) imposibilitatea de a accesa sau îngreunarea accesului la aplicație din cauza unor probleme ce țin de soft-ul utilizat de către Beneficiar sau din cauza unor viruși;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>d) întârzierile în operarea sau transmisiunea datelor provocate ca urmare a incidenței situațiilor prevăzute la literele a, b și c;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>e) prezența unor erori sau probleme legate de funcționarea aplicației, altele decât cele cauzate din pricina celor indicate la literele a, b, c; 
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>f) conduita defăimătoare, ofensivă sau ilegală a altor Beneficiari sau terțe persoane;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>g) conduita iresponsabilă a Beneficiarului în legătură cu datele sale sau cu datele obținute de la persoanele cu care lucrează;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>h) modificarea datelor contului Beneficiarului de către alte persoane;
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>i) utilizarea necorespunzătoare a aplicației; 
  </p>
  <p class="BodyText" style="text-align:justify">
    <span style="width:35.3pt; color:#000000; display:inline-block">&nbsp;</span>j) orice alte fapte străine de voința și puterea de control a Furnizorului;
  </p>
  <p class="BodyText" style="text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="text-align:justify">
    2. Toate informațiile utilizate pentru descrierea aplicației (imagini, video-uri, texte, etc.) sunt utilizate exclusiv în scop de prezentare și nu constituie obligații contractuale pe care Furnizorul și le asumă.
  </p>
  <p class="BodyText" style="text-align:justify">
    3. Furnizorul nu este responsabil de datele pe care Beneficiarul le stochează și centralizează prin intermediul aplicației și nici de eventualele prejudicii cauzate de pierderea acestora.
  </p>
  <p class="BodyText" style="text-align:justify">
    4. Furnizorul nu garantează că durata de funcționate a aplicației va fi una definitivă. Astfel, Beneficiarului îi revine obligația de a crea back -ups (copii de rezervă) după datele ce le încarcă pe aplicație. Furnizorul nu răspunde pentru prejudiciile cauzate ca urmare a întreruperii intempestive a accesului la aplicație, indiferent de motiv.
  </p>
  <p class="BodyText" style="text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>SECȚIUNEA VI. POLITICA DE CONFIDENȚIALITATE</strong>
  </p>
  <h4 style="text-align:justify">
    1. Prevederi generale
  </h4>
  <p class="BodyText" style="text-align:justify">
    Accesând site-ul aplicației toți Beneficiarii sunt de acord să respecte termenii de utilizare și legislația în vigoare. Politica prezentată în continuare vă explică ce fel de date pot fi colectate de Furnizor, cum sunt acestea protejate și utilizate și cum pot fi dezvăluite. Totodată, are ca scop stabilirea unor norme de conduită pentru asigurarea unui nivel satisfăcător de protecție a datelor cu caracter personal prelucrate de către Furnizor și se aplică colectării, folosirii, dezvăluirii și protejării informațiilor personale pe care decideți să ni le furnizați.
  </p>
  <p class="BodyText" style="text-align:justify">
    În vederea respectării REGULAMENTULULUI (UE) 2016/679 AL PARLAMENTULU1 EUROPEAN 51 AL CONSILIULUI din 27 aprilie 2016, privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date (GDPR), Furnizorul are obligația de a administra în condiții de siguranță și numai pentru scopurile specificate datele personale pe care ni le furnizați.
  </p>
  <p class="BodyText" style="text-align:justify">
    În calitate de posesor al datelor cu caracter personal, beneficiați de dreptul de acces, de intervenție asupra datelor, dreptul de a nu fi supus unei decizii individuale și dreptul de a vă adresa justiției. Totodată, aveți dreptul să vă opuneți prelucrării datelor personale care vă privesc și să solicitați ștergerea datelor*. Pentru exercitarea acestor drepturi, vă puteți adresa cu o cerere scrisă, datată și semnată spre Bulevardul Nicolae Titulescu, Nr. 27, Bloc D1, Et. 4, Ap. 9, Cluj-Napoca, Jud. Cluj, România, C.U.I. 48959012, înmatriculată la Registrul Comerțului sub nr. J12/4397/17.10.2023.
  </p>
  <p class="BodyText">
    Observații:
  </p>
  <p class="BodyText" style="text-align:justify">
    *orice persoană are dreptul de a se opune, pentru motive legitime, la prelucrarea datelor ce o privesc. Acest drept de opoziție poate fi exclus pentru anumite prelucrări prevazute de lege (de ex.: prelucrări efectuate de serviciile financiare și fiscale, de poliție, justiție, securitate socială etc). Prin urmare, această mențiune nu poate figura dacă prelucrarea are un caracter obligatoriu;
  </p>
  <h4>
    2. Ce fel de date sunt prelucrate
  </h4>
  <ol style="margin:0pt; padding-left:0pt">
    <li class="BodyText" style="margin-left:35.2pt; margin-bottom:0pt; text-align:justify; padding-left:0.15pt">
      Sunt supuse prelucrării datelor cu caracter personal ale persoanelor care se înregistrează pe site-ul aplicației (Beneficiarul) și ale persoanelor cu care Beneficiarul lucrează (persoanele cu autism). 
    </li>
    <li class="BodyText" style="margin-left:35.2pt; margin-bottom:0pt; text-align:justify; padding-left:0.15pt">
      Sunt prelucrate următoarele categorii de date personale ale Beneficiarului: 
      <ul style="margin-right:0pt; margin-left:0pt; padding-left:0pt; list-style-type:disc">
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; text-align:left; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">date de contact: nume, prenume, adresa de e-mail, număr de telefon </span>
        </li>
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; text-align:left; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">nume de utilizator, parolă logare pe site și data nașterii </span>
        </li>
      </ul>
    </li>
  </ol>
  <p class="BodyText" style="margin-bottom:0pt">
    &nbsp;
  </p>
  <ol start="3" style="margin:0pt; padding-left:0pt">
    <li class="BodyText" style="margin-left:35.2pt; text-align:justify; padding-left:0.15pt">
      Pe lângă datele indicate anterior, Furnizorul prelucrează și datele cu caracter personal ale persoanelor ce suferă de autism pe care Beneficiarul le încarcă pe aplicație și anume: 
    </li>
  </ol>
  <p class="BodyText" style="margin-left:54pt; text-indent:-18pt; text-align:justify">
    <span style="font-family:OpenSymbol">◦</span><span style="width:10.25pt; font:7pt 'Times New Roman'; display:inline-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>nume, prenume, date și rezultate medicale – în măsura în care acestea pot duce la identificarea persoanei- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
  </p>
  <h4>
    3. Cu cine împărtășim datele
  </h4>
  <ol style="margin:0pt; padding-left:0pt">
    <li class="BodyText" style="margin-left:35.2pt; margin-bottom:0pt; padding-left:0.15pt">
      Datele personale pot fi transmise către partenerii noștri de încredere pentru a intermedia accesul la produsele noastre: 
      <ul style="margin-right:0pt; margin-left:0pt; padding-left:0pt; list-style-type:disc">
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">Furnizorul care ne asigură servicii de stocare pe servere externe din SEE </span>
        </li>
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">Furnizori care îți facilitează înregistrarea pe platformă (Facebook)</span><span style="font-family:'Times New Roman'">&nbsp; </span>
        </li>
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">Furnizori care ne ajută să îți oferim tipuri variate de conținut (de exemplu playerul Youtube) </span>
        </li>
        <li class="BodyText" style="margin-left:31.72pt; margin-bottom:0pt; padding-left:3.63pt; font-family:serif">
          <span style="font-family:'Times New Roman'">Furnizori de servicii de marketing prin e-mail; </span>
        </li>
      </ul>
    </li>
  </ol>
  <p class="BodyText" style="margin-bottom:0pt">
    &nbsp;
  </p>
  <ol start="2" style="margin:0pt; padding-left:0pt">
    <li class="BodyText" style="margin-left:35.2pt; text-align:justify; padding-left:0.15pt">
      Atunci când externalizăm anumite activități către partenerii noștri de încredere depunem toate eforturile rezonabile pentru a ne verifica în prealabil dacă aceștia asigură protecția datelor tale prin măsuri stricte de securitate a datelor. 
    </li>
  </ol>
  <h4>
    4. În ce scop utilizăm informațiile pe care le colectăm
  </h4>
  <p class="BodyText" style="text-align:justify">
    În cazul în care ne veți furniza datele dvs. personale, le vom utiliza în general pentru a asigura funcționaliatea aplicației potrivit destinației acesteia (a se vedea secțiunea III), pentru a răspunde solicitărilor dvs., pentru a vă intermedia accesul la informații specifice, oferte sau servicii și pentru a efectua verificări, cercetări și analize pentru a menține, proteja și dezvolta produsele sau serviciile pe care le oferim.
  </p>
  <p class="BodyText" style="text-align:justify">
    Pentru a vă ține la curent și a vă oferi o experiență cât mai completă , vom putea trimite știri, sfaturi privind abordarea unor probleme sau care să vă ajute să utilizați aplicația oferită de noi și alte notificări privind conținutul, ofertele de servicii, promoțiile și alte evenimente, din partea noastră, afiliaților și partenerilor noștri, prin intermediul e-mailului dvs. Prin aceasta vă exprimați acordul în acest sens și luați la cunoștință faptul că puteți avea obiecții și vă puteți revoca acordul oricând bifând opțiunile corespunzătoare de pe pagina profilului dvs. de utilizator.
  </p>
  <p class="BodyText" style="text-align:justify">
    Ne permitem de asemenea să monitorizăm modul în care folosiți serviciile pe care le oferim și să vă contactăm personal pentru a vă oferi sfaturi care să vă fie de ajutor în utilizarea aplicației.
  </p>
  <p class="BodyText" style="text-align:justify">
    Furnizorul va colecta, utiliza sau dezvălui datele personale furnizate de dvs. numai pentru scopurile indicate și conform acestei politici, cu excepția cazului în care comunicarea datelor: se referă la utilizarea datelor personale pentru orice scop adițional care se referă direct la scopul original pentru care datele personale au fost colectate și este necesară pentru a pregăti, negocia sau derula un contract pentru dvs., este solicitată prin lege sau de autoritățile guvernamentale sau juridice competente, este necesară pentru a stabili sau pentru a apăra un drept legal, este necesară pentru a preveni frauda sau alte activități ilegale, precum și împotriva atacurilor intenționate asupra sistemelor de tehnologie a informației ale Furnizorului.
  </p>
  <p class="BodyText" style="text-align:justify">
    Revizuim practicile noastre de colectare, stocare și procesare de date pentru a ne asigura că procesăm, colectăm și stocăm numai acele date necesare pentru furnizarea sau îmbunătățirea aplicației. Luăm măsurile necesare, în măsura posibilităților, pentru a ne asigura că datele personale pe care le procesăm sunt corecte, complete și actuale, dar depindem de dumneavoastră pentru a ne furniza date actualizate sau pentru a corecta datele personale acolo unde este necesar.
  </p>
  <h4>
    5. Date de comunicare sau utilizare
  </h4>
  <p class="BodyText" style="text-align:justify">
    Prin utilizarea serviciilor de telecomunicații pentru a accesa pagina noastră de internet, datele dvs. de comunicare (de ex. adresa Internet protocol address) sau datele de utilizare (de ex. Informațiile de la început, de la sfârșit și extinderea fiecărui acces, precum și informațiile pentru serviciile de telecomunicații pe care le-ați accesat) sunt generate tehnic și se pot relaționa inteligibil la datele personale. În măsura în care există vreo necesitate de compilare, vor fi efectuate colectarea, procesarea și utilizarea datelor dvs. de comunicare sau de utilizare în conformitate cu cadrul legal aplicabil referitor la protecția datelor personale.
  </p>
  <h4>
    6. Date non-personale colectate automat
  </h4>
  <p class="BodyText" style="text-align:justify">
    Când accesaţi paginile noastre de internet, putem colecta automat (nu prin înregistrare) date non-personale (de ex. Tipul de browser internet și sistemul de operare utilizat, numele domeniului paginii Web de pe care ați venit, numărul de vizite, durata medie petrecută pe site, paginile vizionate). Noi putem utiliza aceste date pentru a monitoriza gradul de atracție a paginilor noastre de internet și pentru a le îmbunătăți performanța sau conținutul.
  </p>
  <h4>
    7. "Cookies" – Informații stocate automat în calculatorul dvs.
  </h4>
  <p class="BodyText" style="text-align:justify">
    Atunci când vizionați una din paginile noastre de internet, noi putem stoca unele date pe calculatorul dvs. sub forma unui "cookie" pentru a recunoaște automat calculatorul dvs. la următoarea vizită. Aceste Cookies ne pot fi de ajutor în multe feluri, de exemplu ele sunt necesare pentru a vă putea înregistra pe platformă. Dacă totuși nu doriți să primiți cookies, vă rugăm să configurați browser-ul de internet astfel încât să ștergeți tot ce înseamnă cookies de pe unitatea centrală a calculatorului, să le blocați sau să primiți un avertisment înainte ca ele să fie salvate.
  </p>
  <h4>
    8. Securitate
  </h4>
  <p class="BodyText" style="text-align:justify">
    Pentru a proteja datele dvs. personale de distrugere accidentală sau ilegală, de pierdere sau alterare și de accesul unor persoane neautorizate, Furnizorul utilizează măsuri de securitate tehnice și organizatorice.
  </p>
  <h4>
    9. Linkuri către alte pagini web
  </h4>
  <p class="BodyText" style="text-align:justify">
    Paginile web ale Furnizorului pot conține linkuri către alte pagini web. Furnizorul nu răspunde pentru practicile de securitate sau de conținutul altor pagini web.
  </p>
  <h4>
    10. Copiii
  </h4>
  <p class="BodyText" style="text-align:justify">
    Conform GDPR, utilizatorii care încă nu au împlinit 16 ani necesită acordul părinților pentru a le putea fi prelucrate datele personale.
  </p>
  <h4>
    11. Cât timp vor fi prelucrate datele
  </h4>
  <p class="BodyText" style="text-align:justify">
    Durata prelucrării se întinde pe perioada deținerii unui cont pe platformă, datele stocate fiind șterse în momentul în care beneficiarul solicită în mod expres ștergerea acestuia. Putem păstra anumite informații inclusiv ulterior închiderii contului, în situațiile în care legislația aplicabilă sau interesele noastre legitime o impun.
  </p>
  <h4>
    12. Întrebări și comentarii
  </h4>
  <p class="BodyText" style="text-align:justify">
    Furnizorul va răspunde la toate solicitările rezonabile pentru a revedea și datele dvs. personale, pentru a corecta, modifica sau șterge orice neregularități. Dacă aveți orice fel de întrebări sau comentarii în legătură cu Politica de securitate a datelor personale (de ex. revederea sau actualizarea datelor dvs. personale), vă rugăm să dați clic pe "Contact " în colțul din dreapta jos al acestei ferestre. Pe acea pagină veți găsi modalitățile prin care ne puteți contacta. Pe măsură ce internetul va avansa, va avansa și Politica noastră de securitate a datelor. Noi vom publica pe această pagină modificările la politica noastră de protecție a datelor personale. Vă rugăm să verificați în mod regulat această pagină pentru a fi la curent.
  </p>
  <p class="BodyText" style="text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>SECȚIUNEA VII. COMUNICAREA NOTIFICĂRILOR</strong>
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>1. </strong>Notificările între Beneficiar și Furnizor se vor realiza în scris și vor fi comunicate prin poștă sau prin intermediul serviciilor de curierat&nbsp; la adresa de domiciliu/sediu sau prin e-mail.
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>2.</strong> Sediul și adresa de e-mail ale Furnizorului sunt cele indicate la secțiunea I, punctul 3 iar domiciliul/sediul/adresa de e-mail ale Beneficiarului sau, după caz, ale reprezentantului sunt cele indicate cu ocazia înregistrării pe site-ul web al aplicației.&nbsp;&nbsp; <strong>&nbsp;</strong>
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>3. </strong>Notificările, indiferent de modalitatea de comunicare, trebuie să conțină suficiente date ce permit identificarea Beneficiarului.
  </p>
  <p class="BodyText" style="text-align:justify">
    &nbsp;
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>SECȚIUNEA VIII. DISPOZIȚII FINALE</strong>
  </p>
  <p class="BodyText" style="margin-bottom:0pt; text-align:justify">
    <strong>&nbsp;</strong>
  </p>
  <p class="BodyText" style="text-align:justify">
    <strong>1. </strong>Beneficiarul și/sau, după caz, reprezentantul acceptă că orice litigiu ce ar putea să survină între părți este guvernat și interpretat în concordanță cu legile României, iar Beneficiarul și/sau, după caz, reprezentantul admite să se supună jurisdicției exclusive a instanțelor competente din România. Aplicația poate fi accesată de oriunde în România și de peste hotare. Furnizorul nu oferă nicio asigurare că prezentele prevederi se supun legilor (inclusiv celor de proprietate intelectuală) ale altor țări în afara României. 
  </p>
  <p class="BodyText" style="text-align:justify">
    &nbsp;
  </p>
`;
  return (
    <main className="container">
      <span dangerouslySetInnerHTML={{ __html: htmlString }} />
    </main>
  );
}
