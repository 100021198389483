import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

// todo add validation for email, phone already in use

export default function Register() {
  const { t } = useTranslation();
  const [user, setUser] = useState({ role: 'PARENT', email: '' });
  const [isTokenValid, setIsTokenValid] = useState(true);
  const navigate = useNavigate();
  const { token } = useParams();
  // TODO - add more validation and make it user friendly

  useEffect(() => {
    if (token) {
      fetch(`/api/invite/${token}/validate`, { method: 'GET' })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setIsTokenValid(false);
          } else {
            user.token = token;
            setUser({ ...user, email: data.token.email });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [token]);

  function validateForm() {
    const requiredFields = [
      'first_name',
      'last_name',
      'email',
      'phone',
      'password',
      'confirmedPassword'
    ];

    const isEmptyField = (field) => !user[field] || user[field].trim() === '';
    const hasEmptyRequiredField = requiredFields.some(isEmptyField);

    if (hasEmptyRequiredField) {
      alert(t('Please fill out all the required fields'));
      return false;
    }
    if (user.password !== user.confirmedPassword) {
      // TODO - add proper form validation
      alert(t("Passwords don't match"));
      return false;
    }
    return true;
  }

  function submitForm(event) {
    event.preventDefault();
    if (validateForm()) {
      fetch('/api/register', { method: 'POST', body: JSON.stringify(user) })
        .then((res) => res.json())
        .then((data) => {
          if (!data.error) {
            navigate('/login', {
              state: { message: t('Registration successful!') }
            });
          } else {
            alert(data.error);
          }
        })
        .catch((err) => alert(err));
    }
  }

  if (token && !isTokenValid) {
    return (
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="py-5 text-center">
          <h2>{t('Invalid invite id')}</h2>
        </div>
      </main>
    );
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>{t('Register')}</h2>
      </div>
      <form
        className="needs-validation form-group text-left"
        onSubmit={submitForm}
      >
        <div className="row g-3" style={{ textAlign: 'left' }}>
          <div className="col-sm-6">
            <label className="form-label">{t('First name')}</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              required
              data-testid="first-name"
              onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            />
          </div>
          <div className="col-sm-6">
            <label className="form-label">{t('Last name')}</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              required
              data-testid="last-name"
              onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Email address')}</label>
            <input
              type="email"
              className="form-control"
              placeholder="you@example.com"
              data-testid="email"
              required
              id="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Phone number')}</label>
            <input
              type="tel"
              className="form-control"
              placeholder=""
              data-testid="phone"
              required
              id="phone"
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Password')}</label>
            <input
              type="password"
              className="form-control"
              placeholder=""
              data-testid="password"
              required
              id="password"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Confirm password')}</label>
            <input
              type="password"
              className="form-control"
              placeholder=""
              required
              data-testid="confirm-password"
              onChange={(e) =>
                setUser({ ...user, confirmedPassword: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Account type')}</label>
            <select
              className="form-select"
              required
              data-testid="account-type"
              onChange={(e) => setUser({ ...user, role: e.target.value })}
            >
              <option value="PARENT">{t('Parent')}</option>
              <option value="THERAPIST">{t('Therapist')}</option>
              <option value="COORDINATOR">{t('Coordinator')}</option>
            </select>
          </div>

          <div className="col-12">
            <button
              className="w-100 btn btn-primary btn-lg"
              type="submit"
              data-testid="register-btn"
            >
              {t('Register')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
