import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    fetch('/api/get-session', { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (data.id) {
          setCurrentUser({
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            role: data.role
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const login = (email, role) => {
    setCurrentUser({ email: email, role: role });
  };

  const logout = () => {
    fetch('/api/logout', { method: 'POST' })
      .then((res) => res.json())
      .then(() => {
        setCurrentUser(null);
        navigate('/login');
      })
      .catch((err) => console.log(err));
  };

  const value = {
    currentUser,
    isLoading,
    login,
    logout
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
