import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateEditPlan({ type }) {
  const { t } = useTranslation();
  const childId = useParams().id;
  const planId = useParams().planId;
  const [plan, setPlan] = useState(null);
  const controller = type ? 'sub-plan' : 'plan';
  const navigate = useNavigate();

  useEffect(() => {
    if (!planId) return;
    fetch(`/api/child/${childId}/${controller}/${planId}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setPlan(data.plan);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId, planId]);

  function submitForm(event) {
    event.preventDefault();
    fetch(`/api/child/${childId}/${controller}/create`, {
      method: 'POST',
      body: JSON.stringify(plan)
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          alert(data.message);
          navigate(`/child/${childId}/${controller}/list`);
        } else {
          console.log(data.error);
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="container">
      <div className="py-5 text-center">
        {planId && <h3>{type ? t('Edit sub-plan') : t('Edit plan')}</h3>}
        {!planId && <h3>{type ? t('Create sub-plan') : t('Create plan')} </h3>}
      </div>
      <form
        className="needs-validation form-group text-left"
        onSubmit={submitForm}
      >
        <div className="row g-3" style={{ textAlign: 'left' }}>
          <div className="col-12">
            <label className="form-label">{t('Name')}</label>
            <input
              data-testid="name"
              type="text"
              className="form-control"
              required
              value={plan ? plan.name : ''}
              onChange={(e) => setPlan({ ...plan, name: e.target.value })}
            />
          </div>
          <div className="col-12">
            <label className="form-label">{t('Description')}</label>
            <textarea
              data-testid="description"
              type="text"
              className="form-control"
              required
              value={plan ? plan.description : ''}
              onChange={(e) =>
                setPlan({ ...plan, description: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <button className="w-100 btn btn-primary btn-lg" type="submit">
              {type ? t('Save sub-plan') : t('Save plan')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
