import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AddHelper from '../../components/child_helper/Add';

export default function Helpers({ accessType }) {
  const { t } = useTranslation();
  const id = useParams().id;
  const [helpers, setHelpers] = useState([]);

  useEffect(() => {
    if (!id || !accessType) return;
    fetch(`/api/child/${id}/helpers?access_type=${accessType}`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setHelpers(data.helpers);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [id, accessType]);

  function deleteHelper(helperId) {
    fetch(`/api/child/${id}/helper/${helperId}/delete`, { method: 'POST' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setHelpers(helpers.filter((helper) => helper.id !== helperId));
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  // TODO Extract this to a component
  const helpersListComponent = helpers.map((helper) => (
    <li
      key={helper.id}
      className="list-group-item d-flex justify-content-between align-items-center"
    >
      {helper.first_name + ' ' + helper.last_name}
      <i className="las la-trash" onClick={() => deleteHelper(helper.id)}></i>
    </li>
  ));

  return (
    <div>
      <h2>{accessType === 'ADMIN' ? t('Coordinators') : t('Therapists')}</h2>
      <ul className="list-group">{helpersListComponent}</ul>
      <AddHelper childId={id} accessType={accessType} />
    </div>
  );
}
