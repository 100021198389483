import { useEffect, useState } from 'react';

import EvaluationResult from './EvaluationResult';

export default function LastEvaluations({ itemId, limit = 20 }) {
  const [evaluations, setEvaluations] = useState([]);

  useEffect(() => {
    fetch(`/api/sub-plan-item/${itemId}/evaluations?limit=${limit}`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setEvaluations(data.evaluations);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [itemId, limit]);

  function onDeleteEvaluation(id) {
    setEvaluations(evaluations.filter((evaluation) => evaluation.id !== id));
  }

  return (
    <h4>
      {evaluations.map((evaluation, index) => (
        <EvaluationResult
          key={index}
          evaluation={evaluation}
          onDeleteEvaluation={onDeleteEvaluation}
        />
      ))}
    </h4>
  );
}
