import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function ChildList() {
  const { t } = useTranslation();
  const [childrenList, setChildrenList] = useState([]);

  useEffect(() => {
    fetch('/api/child/list', { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setChildrenList(data.children);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <div className="py-5 text-center">
        <h2>{t('Children')}</h2>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {childrenList.map((child) => (
          <div className="col" key={child.id}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {child.first_name + ' ' + child.last_name}
                </h5>
                <p className="card-text">
                  {t('Date of birth')}: {child.date_of_birth}
                </p>
                <Link
                  to={`/child/${child.id}/dashboard`}
                  className="btn btn-primary"
                >
                  {t('View')}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
