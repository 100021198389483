import { t } from 'i18next';
import { useState } from 'react';

import EvaluationButton from '../evaluation/EvaluationButton';
import LastEvaluations from '../evaluation/LastEvaluations';

export default function EvaluationRow({ item }) {
  const [key, setKey] = useState(0);
  const [visible, setVisible] = useState(true);

  function onEvaluationSent() {
    setKey(key + 1);
  }

  function onStatusChange(event) {
    console.log(event.target.value);
    if (event.target.value !== item.status) {
      fetch(
        `/api/sub-plan-item/${item.id}/set-status?status=${event.target.value}`,
        { method: 'POST' }
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data.error) {
            item.status = event.target.value;
            setVisible(false);
          } else {
            console.log(data.error);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <tr className={visible ? '' : 'd-none'}>
      <td>{item.description}</td>
      <td>
        <EvaluationButton
          itemId={item.id}
          evaluated={false}
          onEvaluationSent={onEvaluationSent}
        />
      </td>
      <td>
        <LastEvaluations itemId={item.id} key={key} limit="5" />
      </td>
      <td>
        <select
          className="form-select"
          onChange={onStatusChange}
          defaultValue={item.status}
        >
          <option value="IN_LEARNING">{t('In learning')}</option>
          <option value="IN_GENERALIZATION">{t('In generalization')}</option>
          <option value="FINISHED">{t('Finished')}</option>
        </select>
      </td>
    </tr>
  );
}
