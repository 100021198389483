import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EvaluationButton({
  childId,
  planId,
  itemId,
  evaluated,
  onEvaluationSent
}) {
  const { t } = useTranslation();
  const [evaluationResult, setEvaluationResult] = useState(evaluated);

  function sendEvaluation(childId, planId, itemId, result) {
    fetch(`/api/sub-plan-item/${itemId}/evaluation/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ result: result })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log('Successfully evaluated:', data);
          setEvaluationResult(result);
          onEvaluationSent();
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function redoEvaluation() {
    setEvaluationResult(null);
  }

  return (
    <>
      {evaluationResult ? (
        <h4>
          <i
            className={`las la-${
              evaluationResult === 'YES' ? 'check' : 'times'
            }-circle icon-large text-${
              evaluationResult === 'YES' ? 'success' : 'danger'
            }`}
          ></i>
          <button
            type="button"
            className="btn btn-primary"
            onClick={redoEvaluation}
          >
            <i className="las la-redo-alt"></i>
          </button>
        </h4>
      ) : (
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => sendEvaluation(childId, planId, itemId, 'YES')}
          >
            {t('Yes')}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => sendEvaluation(childId, planId, itemId, 'NO')}
          >
            {t('No')}
          </button>
        </div>
      )}
    </>
  );
}
