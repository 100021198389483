import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function EvaluatePlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const childId = useParams().id;
  const planId = useParams().planId;
  const [items, setItems] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() => {
    fetch(`/api/child/${childId}/plan/${planId}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          data.plan_items.sort(() => Math.random() - 0.5);
          setItems(data.plan_items);
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [planId]);

  function sendEvaluation(result) {
    fetch(
      `/api/child/${childId}/plan/${planId}/item/${items[itemIndex].id}/evaluation/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ result: result })
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setItemIndex(itemIndex + 1);
          if (itemIndex === items.length - 1 || itemIndex === 6) {
            alert(t('Evaluation completed'));
            navigate(`/child/${childId}/plan/${planId}  `);
          }
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="container-fluid">
      <h1>{items[itemIndex]?.description}</h1>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-success"
          onClick={() => sendEvaluation('YES')}
        >
          {t('Yes')}
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => sendEvaluation('NO')}
        >
          {t('No')}
        </button>
      </div>
    </div>
  );
}
