import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export default function ResetPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  function submitForm(event) {
    event.preventDefault();
    fetch('/api/reset-password', {
      method: 'POST',
      body: JSON.stringify({ email: email })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          navigate('/login', { state: { message: t(data.message) } });
        } else {
          alert(data.error);
        }
      })
      .catch((err) => alert(err));
  }

  return (
    <div className="container">
      <main>
        <div className="py-5 text-center">
          <h2>{t('Reset password')}</h2>
        </div>
        <form
          className="needs-validation form-group text-left"
          onSubmit={submitForm}
        >
          <div className="row g-3" style={{ textAlign: 'left' }}>
            <div className="col-12">
              <label className="form-label">{t('Email address')}</label>
              <input
                type="email"
                className="form-control"
                required
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12">
              <button className="w-100 btn btn-primary btn-lg" type="submit">
                {t('Reset password')}
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}
