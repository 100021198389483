import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import 'bootstrap/dist/js/bootstrap.bundle';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// import './App.css';
import { UserProvider } from './UserContext';
import Footer from './components/Footer';
import SideBar from './components/side_bar/SideBar';

function App() {
  useEffect(() => {
    document.title = 'AbaWell Track';
  }, []);

  return (
    <div className="App">
      <UserProvider>
        <div className="row">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="col-md-9  d-flex flex-column">
            <main className="flex-shrink-0 flex-grow-1">
              <div className="container" style={{ minHeight: '80vh' }}>
                <Outlet />
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </UserProvider>
      <TawkMessengerReact
        propertyId="65411c49a84dd54dc4872f3e"
        widgetId="1he35cvku"
      />
    </div>
  );
}

export default App;
