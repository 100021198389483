import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../UserContext';

export default function UserMenu() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className="dropup">
      <a
        className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        href="#"
      >
        <img
          src="https://learn.wellcode.ro/img/user.png"
          alt=""
          width="32"
          height="32"
          className="rounded-circle me-2"
        />
        <strong>{userContext.currentUser.first_name} </strong>
      </a>
      <ul className="dropdown-menu text-small shadow">
        <li>
          <button
            data-bs-toggle="collapse"
            data-bs-target={window.innerWidth < 768 && '#sidebar'}
            className="dropdown-item"
            onClick={() => navigate('/child/list')}
          >
            {t('Children list')}
          </button>
        </li>
        <li>
          <button
            data-bs-toggle="collapse"
            data-bs-target={window.innerWidth < 768 && '#sidebar'}
            className="dropdown-item"
            onClick={() => navigate('/child/create')}
          >
            {t('Add child')}
          </button>
        </li>
        <li>
          <button
            data-bs-toggle="collapse"
            data-bs-target={window.innerWidth < 768 && '#sidebar'}
            className="dropdown-item"
            onClick={() => navigate('/billing')}
          >
            {t('Billing')}
          </button>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <button className="dropdown-item" onClick={userContext.logout}>
            {t('Logout')}
          </button>
        </li>
      </ul>
    </div>
  );
}
