import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer mt-auto py-3">
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <img
              src="/logo-01.png"
              alt="Company Logo"
              style={{ maxHeight: 50 }}
            />
            <br />
            AbaWell Track
          </div>
          <div className="col py-3">
            <Link to="/terms" className="text-muted">
              {t('Terms and Conditions')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
