import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateSubscription from '../../components/subscription/CreateSubscription';

export default function Subscriptions() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    fetch('/api/subscription/list')
      .then((response) => response.json())
      .then((data) => {
        setSubscriptions(data.subscriptions);
      })
      .catch((err) => alert(err));
  }, [index]);

  function updateSubscriptionStatus(id, status) {
    fetch('/api/subscription/update-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ subscription_id: id, status: status })
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          setIndex(index + 1);
        }
      });
  }

  return (
    <div>
      <h1>{t('Subscriptions')}</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{t('Id')}</th>
            <th>{t('User id')}</th>
            <th>{t('User name')}</th>
            <th>{t('Quantity')}</th>
            <th>{t('Status')}</th>
            <th>{t('Creation date')}</th>
            <th>{t('Last updated')}</th>
            <th>{t('Stripe id')}</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr key={subscription.id}>
              <td>{subscription.id}</td>
              <td>{subscription.user_id}</td>
              <td>
                {subscription.user_first_name +
                  ' ' +
                  subscription.user_last_name}
              </td>
              <td>{subscription.quantity}</td>
              <td>
                <select
                  className="form-select"
                  value={subscription.status}
                  onChange={(e) =>
                    updateSubscriptionStatus(subscription.id, e.target.value)
                  }
                >
                  <option value="active">{t('Active')}</option>
                  <option value="canceled">{t('Canceled')}</option>
                  <option value="paused">{t('Paused')}</option>
                </select>
              </td>
              <td>{subscription.created}</td>
              <td>{subscription.last_update}</td>
              <td>{subscription.stripe_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <CreateSubscription index={index} setIndex={setIndex} />
    </div>
  );
}
