import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function ChildLinks() {
  const { t } = useTranslation();
  const childId = useParams().id;
  const path = useLocation().pathname;
  const navigate = useNavigate();

  return (
    <ul className="nav nav-pills flex-column mb-auto">
      <li className="nav-item">
        {/* in order for element to be active just add the class active */}
        <div
          data-bs-toggle="collapse"
          data-bs-target={window.innerWidth < 768 && '#sidebar'}
          className={`nav-link link-body-emphasis ${
            path.match('/plan/') ? 'active' : ''
          }`}
          aria-current="page"
          onClick={() => navigate(`/child/${childId}/plan/list`)}
        >
          {' '}
          <i className="las la-tasks"></i> &nbsp;
          {t('Plans')}
        </div>
      </li>
      <li>
        <div
          data-bs-toggle="collapse"
          data-bs-target={window.innerWidth < 768 && '#sidebar'}
          className={`nav-link link-body-emphasis ${
            path.match('/sub-plan/') ? 'active' : ''
          }`}
          aria-current="page"
          onClick={() => navigate(`/child/${childId}/sub-plan/list`)}
        >
          {' '}
          <i className="las la-list-ul"></i> &nbsp;
          {t('Sub-plans')}
        </div>
      </li>
      <hr />
      <li>
        <div
          data-bs-toggle="collapse"
          data-bs-target={window.innerWidth < 768 && '#sidebar'}
          className={`nav-link link-body-emphasis ${
            path.match('/behaviors') ? 'active' : ''
          }`}
          onClick={() => navigate(`/child/${childId}/behaviors`)}
        >
          {' '}
          <i className="las la-stopwatch"></i> &nbsp;
          {t('Behavior tracking')}
        </div>
      </li>
      <hr />
      <li>
        <div
          data-bs-toggle="collapse"
          data-bs-target={window.innerWidth < 768 && '#sidebar'}
          className={`nav-link link-body-emphasis ${
            path.match('/coordinators') ? 'active' : ''
          }`}
          onClick={() => navigate(`/child/${childId}/coordinators`)}
        >
          {' '}
          <i className="las la-user-cog"></i> &nbsp;
          {t('Coordinators')}
        </div>
      </li>
      <li>
        <div
          data-bs-toggle="collapse"
          data-bs-target={window.innerWidth < 768 && '#sidebar'}
          className={`nav-link link-body-emphasis ${
            path.match('/therapists') ? 'active' : ''
          }`}
          onClick={() => navigate(`/child/${childId}/therapists`)}
        >
          {' '}
          <i className="las la-user"></i> &nbsp;
          {t('Therapists')}
        </div>
      </li>
    </ul>
  );
}
