import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PAGE_SIZES = [10, 25, 50, 100];

// TODO: Improve pagination and save the number of users per page in cache
export default function Users() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [usersCount, setUsersCount] = useState(10);

  useEffect(() => {
    fetch(`/api/user/list?page=${page}&user-count=${usersCount}`)
      .then((res) => res.json())
      .then((data) => {
        setUsers(data.users);
      })
      .catch((err) => alert(err));
  }, [page, usersCount]);

  const handlePageSizeChange = (e) => {
    setPage(1);
    setUsersCount(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <h1>{t('Users')}</h1>
      <div className="row">
        <div className="col-md-2  d-flex flex-column">
          <select className="form-select" onChange={handlePageSizeChange}>
            {PAGE_SIZES.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link disabled"
                  aria-label="Current Page"
                  disabled
                >
                  <span aria-hidden="true">{page}</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange(page + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{t('Id')}</th>
            <th>{t('First name')}</th>
            <th>{t('Last name')}</th>
            <th>{t('Email')}</th>
            <th>{t('Phone number')}</th>
            <th>{t('Role')}</th>
            <th>{t('Register date')}</th>
            <th>{t('STATUS')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.role}</td>
              <td>
                {new Date(user.date_registered).toLocaleDateString('en-GB')}
              </td>
              <td>{t(`${user.status}`)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
