import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserContext } from '../UserContext';

export default function ProtectedRoute({ role }) {
  const userContext = useContext(UserContext);
  const location = useLocation();

  return (
    <>
      {userContext.isLoading && <h1>Loading...</h1>}
      {!userContext.isLoading && !userContext.currentUser && (
        <Navigate to="/login" state={location.pathname} />
      )}
      {!userContext.isLoading &&
        userContext.currentUser &&
        (!role || role === userContext.currentUser.role) && <Outlet />}
    </>
  );
}
