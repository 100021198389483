import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

export default function TokenResetPassword() {
  const { t } = useTranslation();
  const token = useParams().token;
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  useEffect(() => {
    fetch(`/api/token/${token}/validate`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          navigate('/login', { state: { message: t(data.error) } });
        }
      })
      .catch((err) => alert(err));
  }, [token]);

  function submitForm(event) {
    event.preventDefault();
    if (password !== confirmedPassword) {
      alert(t("Passwords don't match"));
      return false;
    }
    fetch(`/api/token/reset-password`, {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        password: password,
        confirmedPassword: confirmedPassword
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          navigate('/login', { state: { message: t(data.message) } });
        } else {
          alert(data.error);
        }
      })
      .catch((err) => alert(err));
  }

  return (
    <div className="container">
      <main>
        <div className="py-5 text-center">
          <h2>{t('Reset password')}</h2>
        </div>
        <form
          className="needs-validation form-group text-left"
          onSubmit={submitForm}
        >
          <div className="row g-3" style={{ textAlign: 'left' }}>
            <div className="col-12">
              <label className="form-label">{t('Password')}</label>
              <input
                type="password"
                className="form-control"
                required
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label className="form-label">{t('Confirm password')}</label>
              <input
                type="password"
                className="form-control"
                required
                id="confirmedPassword"
                onChange={(e) => setConfirmedPassword(e.target.value)}
              />
            </div>
            <div className="col-12">
              <button className="w-100 btn btn-primary btn-lg" type="submit">
                {t('Reset password')}
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}
