import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ItemsEvaluation from '../../../components/sub_plan/items_evaluation';

export default function ViewPlan() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const childId = useParams().id;
  const planId = useParams().planId;

  const [plan, setPlan] = useState({});
  const [planSubPlans, setPlanSubPlans] = useState([]);
  const [subPlans, setSubPlans] = useState([]);
  const [selectedSubPlan, setSelectedSubPlan] = useState({});
  const [itemsStatus, setItemsStatus] = useState('IN_LEARNING');

  useEffect(() => {
    fetch(
      `/api/child/${childId}/plan/${planId}/detailed-view?items_status=${itemsStatus}`,
      { method: 'GET' }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setPlan(data.plan);
          setPlanSubPlans(data.sub_plans);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));

    fetch(`/api/sub-plan/list?child_id=${childId}`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setSubPlans(data.plans);
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [childId, planId, itemsStatus]);

  function addSubPlan(event) {
    event.preventDefault();
    fetch(`/api/child/${childId}/plan/${planId}/add-sub-plan`, {
      method: 'POST',
      body: JSON.stringify({ sub_plan_id: selectedSubPlan })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          navigate(0);
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
    console.log(selectedSubPlan);
  }

  const planColors = {
    ACTIVE: 'primary',
    INACTIVE: 'secondary',
    COMPLETED: 'success'
  };

  // TODO component for displaying items
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h2>
            {plan.name}
            <span
              className={
                'badge bg-' + planColors[plan.status] + ' rounded-pill'
              }
            >
              {t(plan.status)}
            </span>
          </h2>
        </div>
        <div className="card-body">
          <p className="lead">{plan.description}</p>
        </div>
        <div className="card-footer">
          {t('Add sub-plan')}
          <form className="" onSubmit={addSubPlan}>
            <div className="input-group mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedSubPlan(e.target.value)}
              >
                <option value>{t('Select sub-plan')}</option>
                {/* TODO CLEAN FOLLOWING LINE */}
                {subPlans
                  .filter(
                    (subPlan) =>
                      !planSubPlans
                        .map((subPlan) => subPlan.id)
                        .includes(subPlan.id)
                  )
                  .map((subPlan) => (
                    <option value={subPlan.id} key={subPlan.id}>
                      {subPlan.name}
                    </option>
                  ))}
              </select>
              <button className="btn btn-primary" type="submit">
                {t('Add')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between">
        <h2>{t('Sub-plans')}</h2>
        {/* Add a filter icon and a dropdown for the status */}
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="las la-filter"></i>
          </button>
          <ul className="dropdown-menu" aria-labelledby="statusDropdown">
            <li>
              <div
                className="dropdown-item"
                onClick={() => {
                  console.log('here');
                  setItemsStatus('IN_LEARNING');
                }}
              >
                {t('In learning')}
              </div>
            </li>
            <li>
              <div
                className="dropdown-item"
                onClick={() => setItemsStatus('IN_GENERALIZATION')}
              >
                {t('In generalization')}
              </div>
            </li>
            <li>
              <div
                className="dropdown-item"
                onClick={() => setItemsStatus('FINISHED')}
              >
                {t('Finished')}
              </div>
            </li>
          </ul>
        </div>
      </div>
      {planSubPlans.map((subPlan) => (
        <ItemsEvaluation key={subPlan.id} subPlan={subPlan} />
      ))}
    </div>
  );
}
