import { Link } from 'react-router-dom';

import thirdStepImage from './assets/IMG_3712-portrait.png';
import firstStepImage from './assets/IMG_4195-portrait.png';
import secondStepImage from './assets/IMG_4196-portrait.png';
import logoImage from './assets/LOGO-COLOR.png';
import cellPhonesImage from './assets/Untitled-design-10.png';
import parentAndTherapistImage from './assets/Untitled-design-11.png';
import parentAndChildImage from './assets/Untitled-design-12.png';
import puzzlePiecesImage from './assets/Untitled-design-13.png';
import childPlaying from './assets/Untitled-design-21.png';

export default function LandingPage() {
  return (
    <>
      <div className="container-fluid bg-dark text-white text-center py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img src={logoImage} className="img-fluid mb-3" alt="Logo" />
              <h2>
                <strong>
                  Oferă-i copilului tău șansa optimă de evoluție în terapie.
                </strong>
              </h2>
              <div className="mt-3">
                <Link to="/register" className="btn btn-danger mb-3">
                  Începe acum
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={cellPhonesImage}
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div>
              <h1 className="text-danger text-center">
                <strong> Evită Stagnarea în Progresului Copilului Tău</strong>
              </h1>
              <br />
              <h2 className="text-center">
                Monitorizarea ineficientă nu doar că pierde timp, dar poate avea
                un impact direct asupra evoluției copilului tău:
              </h2>
            </div>
            <div className="col-md-6">
              <ul className="mt-4">
                <li className="mb-3">
                  <b>Stagnare în Terapie: </b>
                  Fără o evidență digitală și clară, copilul tău poate rămâne
                  blocat într-un anumit punct al terapiei mult mai mult timp
                  decât este necesar. Fiecare moment
                  <b> FĂRĂ PROGRES </b>
                  este o oportunitate pierdută.
                </li>
                <li className="mb-3">
                  <b>Lipsă de Transparență: </b>
                  Nu vei ști exact unde se află copilul tău în procesul
                  terapeutic. Această incertitudine poate afecta relația dintre
                  tine, copil și terapeut.
                </li>
                <li>
                  <b>Birocrație și Stres: </b>
                  Sistemul tradițional de monitorizare adaugă presiune și stres,
                  distrăgând atenția de la obiectivul principal:
                  <b> PROGRESUL COPILULUI.</b>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img
                src={childPlaying}
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white py-5">
        <div className="container text-center">
          <h1 className="text-danger">
            <strong>
              Oferă-i copilului tău șansa optimă de evoluție în terapie.
            </strong>
          </h1>
          <h1 className="text-danger">Transformă Experiența Terapiei ABA!</h1>
          <br />
          <div className="row justify-content-center">
            <div className="col-md-4 mt-4">
              <h1>PASUL 1</h1>
              <img
                src={firstStepImage}
                className="img-fluid mb-2"
                alt="Pasul 1"
              />
              <b>Fă-ți un cont pe platforma ABAWELLTRACK</b>
            </div>
            <div className="col-md-4 mt-4">
              <h1>PASUL 2</h1>
              <img
                src={secondStepImage}
                className="img-fluid mb-2"
                alt="Pasul 2"
              />
              <b>Adaugă planul copilului tău direct de pe telefon</b>
            </div>
            <div className="col-md-4 mt-4">
              <h1>PASUL 3</h1>
              <img
                src={thirdStepImage}
                className="img-fluid mb-2"
                alt="Pasul 3"
              />
              <b>Colectează date în timp real de pe telefon</b>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <h1 className="text-danger">
                <strong>TRANSPARENȚĂ ABSOLUTĂ</strong>
              </h1>
              <br />
              <h1>
                Părinții merită să înțeleagă și să fie parte activă în procesul
                terapeutic. Oferim o viziune clară și deschisă asupra evoluției
                copilului tău, creând o legătură strânsă între tine și terapeut.
              </h1>
            </div>
            <div className="col-md-6">
              <img
                src={parentAndTherapistImage}
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img
                src={parentAndChildImage}
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <h1 className="text-danger text-center">
                <strong>CLARITATE</strong>
              </h1>
              <br />
              <h1 className="text-center">
                Vei știi tot timpul ce poți să lucrezi în timpul din afara
                terapiei. Îl va ajuta pe copil să generalizeze obiectivele.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <h1 className="text-danger">
                <strong>EFICIENȚĂ MAXIMĂ</strong>
              </h1>
              <br />
              <h1>
                Uită de orele pierdute cu monitorizarea manuală. Platforma
                noastră digitală colectează date din fiecare sesiune de terapie
                ABA, permițându-ți să te concentrezi mai mult pe copilul tău.
              </h1>
            </div>
            <div className="col-md-6">
              <img
                src={puzzlePiecesImage}
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
