import 'bootstrap/dist/css/bootstrap.css';
import { use } from 'i18next';
import HttpApi from 'i18next-http-backend';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import ChildAccessControl from './components/ChildAccessControl';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LandingPage from './routes/LandingPage/LandingPage';
import ProtectedRoute from './routes/ProtectedRoute';
import Subscriptions from './routes/admin/subscriptions';
import Users from './routes/admin/users';
import Billing from './routes/billing/billing';
import BehaviorList from './routes/child/behaviors/list';
import ViewBehavior from './routes/child/behaviors/view';
import CreateChild from './routes/child/create';
import Dashboard from './routes/child/dashboard';
import Helpers from './routes/child/helpers';
import ChildList from './routes/child/list';
import CreateEditPlan from './routes/child/plan/create_edit';
import EvaluatePlan from './routes/child/plan/evaluate';
import PlanList from './routes/child/plan/list';
import ViewPlan from './routes/child/plan/view';
import ViewSubPlan from './routes/child/sub-plan/view';
import Login from './routes/login';
import Register from './routes/register';
import ResetPassword from './routes/reset-password';
import Terms from './routes/terms';
import ActivateUser from './routes/token/activate-user';
import TokenResetPassword from './routes/token/reset-password';

use(HttpApi).use(initReactI18next).init({
  debug: true,

  loadPath: '/locales/{{lng}}/translation.json',
  lng: 'ro', // if you're using a language detector, do not define the lng option
  fallbackLng: 'en'
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'register/:token',
        element: <Register />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'terms',
        element: <Terms />
      },
      {
        path: '/token/reset-password/:token',
        element: <TokenResetPassword />
      },
      {
        path: '/token/activate-user/:token',
        element: <ActivateUser />
      },
      {
        path: '/billing',
        element: <Billing />
      },
      {
        path: '/admin',
        element: <ProtectedRoute role="ADMIN" />,
        children: [
          {
            path: '/admin/subscriptions',
            element: <Subscriptions />
          },
          {
            path: '/admin/users',
            element: <Users />
          }
        ]
      },
      {
        path: '/child',
        element: <ProtectedRoute />,
        children: [
          {
            // todo add permissions
            path: '/child/create',
            element: <CreateChild />
          },
          {
            path: '/child/list',
            element: <ChildList />
          },
          {
            path: '/child/:id',
            element: <ChildAccessControl />,
            children: [
              {
                path: '/child/:id/dashboard',
                element: <Dashboard />
              },
              {
                path: '/child/:id/behaviors',
                element: <BehaviorList />
              },
              {
                path: '/child/:id/behavior/:behaviorId',
                element: <ViewBehavior />
              },
              {
                path: '/child/:id/coordinators',
                element: <Helpers accessType="ADMIN" />
              },
              {
                path: '/child/:id/therapists',
                element: <Helpers accessType="THERAPIST" />
              },
              {
                path: '/child/:id/sub-plan/list',
                element: <PlanList type="sub-plan" />
              },
              {
                path: '/child/:id/sub-plan/:planId/edit',
                element: <CreateEditPlan type="sub-plan" />
              },
              {
                path: '/child/:id/sub-plan/:planId',
                element: <ViewSubPlan />
              },
              {
                path: '/child/:id/sub-plan/create',
                element: <CreateEditPlan type="sub-plan" />
              },
              {
                path: '/child/:id/plan/:planId/edit',
                element: <CreateEditPlan />
              },
              {
                path: '/child/:id/plan/:planId/evaluate',
                element: <EvaluatePlan />
              },
              {
                path: '/child/:id/plan/:planId',
                element: <ViewPlan />
              },
              {
                path: '/child/:id/plan/list',
                element: <PlanList />
              },
              {
                path: '/child/:id/plan/create',
                element: <CreateEditPlan />
              }
            ]
          }
        ]
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
