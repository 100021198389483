import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export default function AddHelper({ childId, accessType }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  function addHelper() {
    fetch(`/api/child/${childId}/helper/add?access_type=${accessType}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, access_type: accessType })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          // TODO print some kind of success message
          setEmail('');
          navigate(0);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder={t('Email')}
          aria-label="Email"
          aria-describedby="button-addon2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id="button-addon2"
          onClick={addHelper}
        >
          {t('Add')}
        </button>
      </div>
    </div>
  );
}
