import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LastEvaluations from '../../../components/evaluation/LastEvaluations';

export default function ViewSubPlan() {
  const { t } = useTranslation();
  const childId = useParams().id;
  const planId = useParams().planId;
  const [plan, setPlan] = useState({});
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ description: '' });

  useEffect(() => {
    fetch(`/api/child/${childId}/sub-plan/${planId}/detailed-view`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setItems(data.items);
          setPlan(data.sub_plan);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [planId, childId]);

  function createItem() {
    if (!newItem.description) return;
    fetch('/api/sub-plan-item/create', {
      method: 'POST',
      body: JSON.stringify({
        item: { description: newItem.description, sub_plan_id: planId }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          console.log('Created successfully:', data);
          setItems([...items, data.item]);
          setNewItem({ description: '' });
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  function deleteItem(itemId) {
    fetch(`/api/sub-plan-item/${itemId}/delete`, { method: 'POST' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setItems(items.filter((item) => item.id !== itemId));
        } else {
          alert(data.error);
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  const planColors = {
    ACTIVE: 'primary',
    INACTIVE: 'secondary',
    COMPLETED: 'success'
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <h2>
            {plan.name}
            <span
              className={
                'badge bg-' + planColors[plan.status] + ' rounded-pill'
              }
            >
              {t(plan.status)}
            </span>
          </h2>
          <p className="lead">{plan.description}</p>
        </div>
      </div>
      <br />
      <h3>{t('Items')}</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{t('Name')}</th>
            <th scope="col">{t('Last evaluations')}</th>
            <th scope="col">{t('Status')}</th>
            <th scope="col">{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          <tr key="add-new-item">
            <td colSpan={3}>
              <input
                type="text"
                className="form-control"
                id="name"
                required
                value={newItem.description}
                onChange={(e) =>
                  setNewItem({ ...newItem, description: e.target.value })
                }
              />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-primary"
                onClick={createItem}
              >
                {t('Create item')}
              </button>
            </td>
          </tr>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.description}</td>
              <td>
                <LastEvaluations itemId={item.id} limit="100" />
              </td>
              <td>{t(item.status)}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteItem(item.id)}
                >
                  {t('Delete')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
