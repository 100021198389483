import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { UserContext } from '../UserContext';

export default function ChildAccessControl() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const childId = useParams().id;
  const planId = useParams().planId;

  const isSubPlan = window.location.pathname.includes('sub-plan');
  const controller = isSubPlan ? 'sub-plan' : 'plan';

  const [hasChildAccess, setHasChildAccess] = useState(true);
  const [hasPlanAccess, setHasPlanAccess] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  const [child, setChild] = useState({});

  useEffect(() => {
    fetch(`/api/child/${childId}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (
          data.error === "You don't have access to this child" ||
          data.error === 'Child does not exist'
        ) {
          setHasChildAccess(false);
          setErrorMessages(data.error);
        } else if (data.error === 'Child subscription not found') {
          navigate('/billing', {
            state: {
              message:
                'This child has no active licenses. Please add a license!'
            }
          });
        } else {
          setChild(data.child);
        }
      })
      .catch((err) => console.log(err));

    if (planId) {
      fetch(`/api/child/${childId}/${controller}/${planId}/detailed-view`, {
        method: 'GET'
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setHasPlanAccess(false);
            setErrorMessages(data.error);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [childId, planId]);

  function deleteChild() {
    if (window.confirm(t('Are you sure you want to delete this child?'))) {
      fetch(`/api/child/${childId}/delete`, { method: 'POST' })
        .then((res) => res.json())
        .then((data) => {
          if (!data.error) {
            navigate('/child/create');
          } else {
            console.log(data.error);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  if (userContext.isLoading) {
    return <h1>{t('Loading')}...</h1>;
  }

  if (!hasChildAccess || !hasPlanAccess) {
    return (
      <div
        className="d-flex flex-column align-items-center 
          justify-content-center vh-100"
      >
        <div className="alert alert-danger" role="alert">
          {t(`${errorMessages}`)}
        </div>
        <Button
          sx={{ textTransform: 'none' }}
          variant="primary"
          onClick={() => navigate('/')}
        >
          {`${t('Back to')} ${t('Home Page')}`}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h1>
        {child ? child.first_name + ' ' + child.last_name : t('Loading')}
        <a className="link">
          <i className="las la-trash-alt" onClick={deleteChild}></i>
        </a>
      </h1>
      <Outlet />
    </div>
  );
}
