import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import RadioList from '../RadioList';
import ABCList from './ABCList';

export default function ABC({ behaviorId }) {
  const { t } = useTranslation();
  const [behavior, setBehavior] = useState({});
  const [activeAntecedent, setActiveAntecedent] = useState(null);
  const [activeConsequence, setActiveConsequence] = useState(null);

  useEffect(() => {
    fetch(`/api/behavior/${behaviorId}`, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setBehavior(data.behavior);
        } else {
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }, [behaviorId]);

  function onSubmit() {
    if (!activeAntecedent || !activeConsequence) {
      alert(t('Please select antecedent and consequence'));
      return;
    }
    fetch(`/api/child/${behavior.child_id}/behavior-abc/log`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        behavior_id: behavior.id,
        antecedent: activeAntecedent,
        consequence: activeConsequence
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          window.location.reload();
        } else {
          alert(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <Container>
      <Row>
        <h2 data-testid="behavior-name">{behavior.name}</h2>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div
            className="sticky-top bg-primary text-white 
            p-3 mt-1 rounded-bottom-2"
          >
            <h3 data-testid="antecedent-title" className="text-center m-0">
              {t('Antecedent')}
            </h3>
          </div>
          <div className="mt-1">
            <RadioList type="antecedent" onSelect={setActiveAntecedent} />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div
            className="sticky-top bg-primary text-white 
            p-3 mt-1 rounded-bottom-2"
          >
            <h3 data-testid="consequence-title" className="text-center m-0">
              {t('Consequence')}
            </h3>
          </div>
          <div className="mt-1">
            <RadioList type="consequence" onSelect={setActiveConsequence} />
          </div>
        </Col>
      </Row>
      <Row className="text-center d-grid gap-2 mt-2 m-0">
        <Button
          data-testid="save-btn"
          className="btn btn-success"
          onClick={onSubmit}
          disabled={!activeAntecedent || !activeConsequence}
        >
          {t('Save')}
        </Button>
      </Row>
      <Row className="mt-3">
        <ABCList behaviorId={behaviorId} />
      </Row>
    </Container>
  );
}
