import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CreateSubscription({ index, setIndex }) {
  const { t } = useTranslation();
  const [subscriptionData, setSubscriptionData] = useState({});

  function createSubscription() {
    fetch('/api/subscription/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(subscriptionData)
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          alert(t('Subscription created!'));
          setIndex(index + 1);
        } else {
          alert(data.error);
        }
      });
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3>{t('Create subscription')}</h3>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label htmlFor="user_id">{t('User email')}</label>
          <input
            type="text"
            className="form-control"
            id="user_id"
            onChange={(e) =>
              setSubscriptionData({
                ...subscriptionData,
                user_email: e.target.value
              })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="quantity">{t('Quantity')}</label>
          <input
            type="text"
            className="form-control"
            id="quantity"
            onChange={(e) =>
              setSubscriptionData({
                ...subscriptionData,
                quantity: e.target.value
              })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="stripe_id">{t('Stripe id')}</label>
          <input
            type="text"
            className="form-control"
            id="stripe_id"
            onChange={(e) =>
              setSubscriptionData({
                ...subscriptionData,
                stripe_id: e.target.value
              })
            }
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={createSubscription}
        >
          {t('Create')}
        </button>
      </div>
    </div>
  );
}
