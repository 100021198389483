import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import EvaluationRow from '../evaluation/EvaluationRow';

export default function ItemsEvaluation({ subPlan }) {
  const { t } = useTranslation();
  const planId = useParams().planId;
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  function removeSubPlan() {
    fetch(`/api/plan/${planId}/sub-plan/${subPlan.id}/delete`, {
      method: 'POST'
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          navigate(0);
        } else {
          console.log(data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <br />
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>
            {subPlan.name}
            <a className="link" onClick={removeSubPlan}>
              <i className="las la-trash"></i>
            </a>
          </h3>
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            onClick={toggleExpanded}
            data-bs-target={'#collapseContent' + subPlan.id}
          >
            {expanded && <i className="las la-chevron-up"></i>}
            {!expanded && <i className="las la-chevron-down"></i>}
          </button>
        </div>
        <div className="card-body collapse" id={'collapseContent' + subPlan.id}>
          <h4>{t('Items')}</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('Name')}</th>
                <th scope="col">{t('Evaluate')}</th>
                <th scope="col">{t('Last evaluations')}</th>
                <th>{t('Status')}</th>
              </tr>
            </thead>
            <tbody>
              {subPlan.items.map((item) => (
                <EvaluationRow key={item.id} item={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
